import {
  ContactData,
  DemandeType,
  FirebaseFirestore,
  MessageData,
} from '@innedit/innedit';
import { navigate } from 'gatsby';
import moment from 'moment';
import React, { FC, memo, SyntheticEvent, useEffect, useState } from 'react';

import IconDelete from '../../icons/Delete';
import IconEye from '../../icons/Eye';
import { ListItemProps } from '../../sysext/CMS/containers/Boutique/components/new/props';
import Button from '../Button';
import ItemProduits from './ItemProduits';
import { ItemDateEl, ItemInfosEl, ItemInfosTextSC, MessageEl } from './styles';

const Item: FC<ListItemProps<DemandeType>> = ({ docId, model }) => {
  const [contact, setContact] =
    useState<firebase.default.firestore.DocumentSnapshot>();
  const [message, setMessage] =
    useState<firebase.default.firestore.DocumentSnapshot>();

  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<DemandeType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  useEffect(() => {
    let unsub: (() => void) | undefined;
    if (model.boutique && doc && doc.get('contact')) {
      const contactModel = new ContactData({ boutique: model.boutique });
      unsub = contactModel.watchById(
        doc.get('contact'),
        (snapshot: firebase.default.firestore.DocumentSnapshot): void => {
          if (snapshot.exists) {
            setContact(snapshot);
          }
        },
      );
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [model.boutique, doc]);

  useEffect(() => {
    let unsub: () => void;
    if (model.boutique && doc && doc.get('message')) {
      unsub = MessageData.watchById(
        doc.get('message'),
        (snapshot: firebase.default.firestore.DocumentSnapshot): void => {
          if (snapshot.exists) {
            setMessage(snapshot);
          }
        },
        {
          boutique: model.boutique,
        },
      );
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [model.boutique, doc]);

  if (!doc) {
    return null;
  }

  const data = doc.data();

  const handleOnClick = () => {
    navigate(`/boutique/contacts/update/${doc.get('contact')}/`);
  };

  const handleMarkAsAnswered = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (contact) {
      ContactData.markAsAnswered(contact.id, { boutique: model.boutique });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <MessageEl>
      <ItemDateEl>
        <span className="day">{moment(data.createdAt).format('Do')}</span>
        <span className="month">{moment(data.createdAt).format('MMM')}</span>
        <span className="time">{moment(data.createdAt).format('HH:mm')}</span>
      </ItemDateEl>
      <ItemInfosEl>
        {contact && (
          <strong className="contact">
            {contact.get('firstName')} {contact.get('lastName')}
          </strong>
        )}
        {message && (
          <>
            <ItemInfosTextSC>{message.get('text')}</ItemInfosTextSC>
            {message.get('priceProducts') &&
              message.get('priceProducts').length > 0 && (
                <ItemProduits
                  boutique={model.boutique}
                  produitIds={message.get('priceProducts')}
                />
              )}
          </>
        )}
      </ItemInfosEl>
      <div className="flex space-x-1 self-start">
        <Button iconLeft={IconEye} onClick={handleOnClick} status="secondary" />
        <Button
          iconLeft={IconDelete}
          onClick={handleMarkAsAnswered}
          status="delete-action"
        />
      </div>
      <div />
    </MessageEl>
  );
};

export default memo(Item);
