import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import DemandeList from '../../core/sysext/Boutique/containers/Demande/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const DemandesPage: FC<PageProps> = props => {
  const { location } = props;

  return (
    <Backoffice location={location}>
      <DemandeList {...props} />
    </Backoffice>
  );
};

export default DemandesPage;
